/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.right-panel{
  position: absolute;
  top:73px;
  right:0;
  bottom:44px;
  left:calc(100% - 50vw);
  /* background-color: #fff; */
  /* border-left: solid 1px rgba(0, 0, 0, 0.12); */
}
.oi-header h1, .oi-header h2, .oi-header h3, .oi-header h4, .oi-header h5{
  padding:0;
  margin:0;
  font-size:18px;
}
@media screen and (max-width: 600px) {
  .right-panel{
      right:0;
      left:0;
      top:56px;
  }
}
